import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

import ServeIcon from "images/serve-png.png"
import CustomizeIcon from "images/customize-png.png"
import AiIcon from "images/artificial-intelligence-png.png"
import ScheduleIcon from "images/schedule-png.png"
import SvgIcon from "images/svg-png.png"

const Container = tw.div`relative h-screen flex items-center `;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row items-center items-stretch md:flex-row flex-wrap justify-center max-w-screen-lg mx-auto py-0 md:py-10`}// ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-10 md:py-10`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full mb-10 sm:mb-2`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`md:mt-10 w-full`;

const Column = styled.div`
  ${tw`w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col mb-4 sm:flex-row items-center sm:items-start text-center sm:text-left h-full md:mx-4 md:px-1 md:py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full md:p-0 flex-shrink-0`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`md:mt-4 tracking-wide font-bold text-[15px] md:text-xl leading-none`}
  }

  .description {
    ${tw`mt-1  font-medium text-secondary-100 text-sm md:leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ServeIcon,
      title: "Effortless Integration",
      description:
        "Our intuitive platform lets you add animations to your website without any coding expertise.",
    },
    { imageSrc: CustomizeIcon, title: "Dazzling Customization", description: "Tailor animations to match your brand's aesthetics and create a unique online experience." },
    { imageSrc: AiIcon, title: "AI-Driven Magic", description: "Explore our AI-powered animation selector that brings out the emotions in your images." },
    { imageSrc: ScheduleIcon, title: "Scheduled Surprises", description: "Set up animations to appear on specific dates or for particular regions, enhancing user engagement." },
    {
      imageSrc:  SvgIcon,
      title: "SVG Masterpieces",
      description: "Unleash your creativity with SVG-based animations that add depth and charm to your content.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <>
        <Container>
          <ThreeColumnContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <VerticalSpacer />
            {cards.map((card, i) => (
              <Column key={i}>
                <Card>
                  <span className="imageContainer">
                    <img src={card.imageSrc || defaultCardImage} alt="" />
                  </span>
                  <span className="textContainer">
                    <span className="title">
                      {card.title || "Fully Secure"}
                    </span>
                    <p className="description">
                      {card.description ||
                        "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                    </p>
                  </span>
                </Card>
              </Column>
            ))}
          </ThreeColumnContainer>
          <DecoratorBlob className="fetureBlob" />
        </Container>
    </>
  );
};
