import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import tw from "twin.macro";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import { ReactComponent as svgEditorImageSrc } from "images/svg-editor.svg";
import { ReactComponent as scheduleImageSrc } from "images/schedule.svg";
import { ReactComponent as regionImageSrc } from "images/region.svg";
import { ReactComponent as cssEditorImageSrc } from "images/css-editor.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as AiImageSrc } from "images/ai.svg";
import { ReactComponent as DummyImageSrc } from "images/design-illustration-2.svg";
import Header from "../components/headers/light.js";
import styled from "styled-components";
import useScroll from "hooks/useScroll.js";
import lottie from "lottie-web";
import animationData from "../assembly-line.json";
import Lottie from "react-lottie";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;
const Container = styled("section")`
  ${tw`fixed w-full h-full top-0 invisible`};
  will-change: transform;
`;
const OuterContainer = styled("div")`
  ${tw`w-full h-full overflow-y-hidden`};
  will-change: transform;
`;
const InnerContainer = styled("div")`
  ${tw`w-full h-full overflow-y-hidden`};
  will-change: transform;
  position: relative;
`;

const ComponentContainer = styled("div")`
  ${tw`w-full h-full overflow-y-hidden absolute top-0 px-1 py-2`};
  // will-change: transform;
`;

const bgColors = {
  panel1: "linear-gradient(234.48deg, #FFDFE4 0%, rgba(255, 255, 255, 1) 100%)",
  panel2:
    "linear-gradient(124.86deg, rgb(183 207 252) 0%, rgb(255, 249, 234) 100%)",
  panel3:
    "linear-gradient(124.86deg, rgb(214 255 146) 0%, rgb(234 244 255) 100%)",
  panel4:
    "linear-gradient(248.77deg, #D2EAFC 8.47%, rgba(250, 253, 255, 1) 100%)",
  panel5:
    "linear-gradient(248.77deg, rgba(251, 253, 255, 1) 8.47%, #F1FFD0 100%)",
  panel6:
    "linear-gradient(284.42deg, rgba(251, 253, 255, 1) 7.66%, #D2D6FD 100%)",
  panel7:
    "linear-gradient(254.84deg, rgba(255, 255, 255, 1) 5.71%, #E8E8E8 100%)",
  panel8:
    "linear-gradient(241.09deg, rgb(241 241 241) 8.81%, rgb(255 232 232) 100%)",
  panel9:
    "linear-gradient(241.09deg, #C8C8C8 8.81%, rgba(232, 232, 232, 1) 100%)",
};

const LottieToGSAP = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Load Lottie animation
    const animation = lottie.loadAnimation({
      container: containerRef.current,
      path: "../assembly-line.json",
      renderer: "svg",
      loop: true,
      autoplay: false,
      xhrSettings: {
        responseType: "text", // Set the 'responseType' to 'text'
      },
    });

    // Convert to GSAP animation
    const timeline = gsap.timeline();
    timeline
      .fromTo(containerRef.current, { opacity: 0 }, { opacity: 1, duration: 1 })
      .to(containerRef.current, { x: "+=100px", y: "+=100px", duration: 2 })
      .to(containerRef.current, { rotation: 360, duration: 1 });

    // Play GSAP animation
    timeline.play();

    // Clean up
    return () => {
      timeline.kill();
      animation.destroy();
    };
  }, []);

  return <div id="vivi" ref={containerRef} />;
};

export default () => {
  const [showHeader, setShowHeader] = useState(false);
  useScroll();

  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: animationData,
  };

  const eventListeners = [
    {
      eventName: "loopComplete",
      callback: () => console.log("a loop complete"),
    },
  ];

  return (
    <>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
            <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-10">
                    <div class="overlay">1</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{ background: bgColors.panel1 }}
              className="component-container"
            >
              <Hero bgColor={"panel-1"} roundedHeaderButton={true} />
            </ComponentContainer>
            <div class="scroll-down">
              Scroll down<div class="arrow"></div>
            </div>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-20">
                    <div class="overlay">2</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{ background: bgColors.panel2 }}
              className="component-container"
            >
              <Features
                bgColor={"panel-2"}
                subheading={<Subheading></Subheading>}
                heading={
                  <div className="shine-text duration-[20000ms]">
                    Vivifyn's amazing features.
                  </div>
                }
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-30">
                    <div class="overlay">3</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{
                background: bgColors.panel3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="component-container"
            >
              <FeatureWithSteps
                subheading={<Subheading></Subheading>}
                bgColor={"panel-4"}
                heading={<>Dazzling Customization</>}
                textOnLeft={false}
                description="No two brands are alike, and that's why Vivifyn empowers you to customize animations to align with your brand's identity. Create a cohesive online experience by tailoring animations to match your brand's colors, style, and personality."
                ImageSrc={DummyImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-40">
                    <div class="overlay">4</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{
                background: bgColors.panel4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="component-container"
            >
              <MainFeature
                subheading={<Subheading></Subheading>}
                bgColor={"panel-3"}
                heading={<>Effortless Integration</>}
                ImageSrc={cssEditorImageSrc}
                imageBorder={true}
                imageDecoratorBlob={true}
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-50">
                    <div class="overlay">5</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{
                background: bgColors.panel5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="component-container"
            >
              <FeatureWithSteps
                subheading={<Subheading></Subheading>}
                bgColor={"panel-4"}
                heading={<>SVG Masterpieces</>}
                textOnLeft={false}
                ImageSrc={svgEditorImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-60">
                    <div class="overlay">6</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{
                background: bgColors.panel6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="component-container"
            >
              <MainFeature2
                bgColor={"panel-5"}
                subheading={<Subheading></Subheading>}
                heading={<>Scheduled Surprises</>}
                ImageSrc={scheduleImageSrc}
                showDecoratorBlob={false}
                // features={[
                //   {
                //     Icon: MoneyIcon,
                //     title: "Affordable",
                //     description:
                //       "We promise to offer you the best rate we can - at par with the industry standard.",
                //     iconContainerCss: tw`bg-green-300 text-green-800`,
                //   },
                //   {
                //     Icon: BriefcaseIcon,
                //     title: "Professionalism",
                //     description:
                //       "We assure you that our templates are designed and created by professional designers.",
                //     iconContainerCss: tw`bg-red-300 text-red-800`,
                //   },
                // ]}
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-80">
                    <div class="overlay">7</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{
                background: bgColors.panel7,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="component-container"
            >
              <FeatureWithSteps
                bgColor={"panel-6"}
                subheading={<Subheading></Subheading>}
                heading={<>Geo Location</>}
                textOnLeft={false}
                ImageSrc={regionImageSrc}
                description="Enhance user engagement by scheduling animations for specific dates or regions. Surprise your audience with animations that align with events or holidays."
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-90">
                    <div class="overlay">8</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{
                background: bgColors.panel8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="component-container"
            >
              <MainFeature2
                bgColor={"panel-5"}
                subheading={<Subheading></Subheading>}
                heading={<>AI-Driven Magic</>}
                // ImageSrc={AiImageSrc}
                // AnimComponent={<Lottie
                //   width={300}
                //   eventListeners={eventListeners}
                //   options={defaultOptions}
                // />}
                description="Experience the magic of our AI-powered animation selector. Let our platform analyze your images and suggest animations that amplify the emotions they convey. This unique feature adds a layer of depth to your website's visuals and makes them resonate with your audience."
                showDecoratorBlob={false}
                features={[
                  {
                    Icon: MoneyIcon,
                    title: "Affordable",
                    description:
                      "We promise to offer you the best rate we can - at par with the industry standard.",
                    iconContainerCss: tw`bg-green-300 text-green-800`,
                  },
                  {
                    Icon: BriefcaseIcon,
                    title: "Professionalism",
                    description:
                      "We assure you that our templates are designed and created by professional designers.",
                    iconContainerCss: tw`bg-red-300 text-red-800`,
                  },
                ]}
              />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
      <Container className="section-container">
        <OuterContainer className="outer-container">
          <InnerContainer className="inner-container">
          <div className="indicator">
              <div>
                <div class="wrap">
                  <div class="progress-radial progress-100">
                    <div class="overlay">9</div>
                  </div>
                </div>
              </div>
            </div>
            <ComponentContainer
              style={{ background: bgColors.panel9 }}
              className="component-container"
            >
              {showHeader && <Header roundedHeaderButton={true} />}
              <GetStarted />
              <Footer />
            </ComponentContainer>
          </InnerContainer>
        </OuterContainer>
      </Container>
    </>
  );
};
