import { useEffect, useState } from "react";
import gsap from "gsap";

const useScroll = () => {
  let sectionContainers,
    componentContainers,
    outerWrappers,
    innerWrappers,
    clamp;

  let animating = false,
    currentIndex = -1;

  const touch = {
    startX: 0,
    startY: 0,
    dx: 0,
    dy: 0,
    startTime: 0,
    dt: 0,
  };
  const tlDefaults = {
    ease: "slow.inOut",
    duration: 1.25,
  };

  const gotoSection = (i, direction) => {
    console.log("called")
    let index = clamp(i);

    if (currentIndex === index) {
      return;
    }
    if (direction == 1) {
      setTimeout(() => {
        planAnimation(index);
        console.log("done animating");
      }, index === 0 ? 1250 : 500);
    }
    animating = true;
    let fromTop = direction === -1,
      dFactor = fromTop ? -1 : 1,
      tl = gsap.timeline({
        defaults: { duration: 1.25, ease: "power1.inOut" },
        onComplete: () => (animating = false),
      });
    if (currentIndex >= 0) {
      // The first time this function runs, current is -1
      gsap.set(sectionContainers[currentIndex], { zIndex: 0 });
      tl.to(componentContainers[currentIndex], {
        yPercent: -15 * dFactor,
      }).set(sectionContainers[currentIndex], { autoAlpha: 0 });
    }

    gsap.set(sectionContainers[index], { autoAlpha: 1, zIndex: 1 });
    tl.fromTo(
      [outerWrappers[index], innerWrappers[index]],
      { yPercent: (i) => (i ? -100 * dFactor : 100 * dFactor) },
      { yPercent: 0 },
      0
    ).fromTo(
      componentContainers[index],
      { yPercent: 15 * dFactor },
      { yPercent: 0 },
      0
    );

    currentIndex = index;
  };

  const handleWheel = (e) => {
    if (animating) return;

    e.wheelDeltaY < 0
      ? gotoSection(currentIndex + 1, 1)
      : gotoSection(currentIndex - 1, -1);
  };

  const handleTouchStart = (e) => {
    const t = e.changedTouches[0];
    touch.startX = t.pageX;
    touch.startY = t.pageY;
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
  };

  const handleTouchEnd = (e) => {
    if (animating) return;
    const t = e.changedTouches[0];
    touch.dx = t.pageX - touch.startX;
    touch.dy = t.pageY - touch.startY;
    if (touch.dy > 10) gotoSection(currentIndex - 1, -1);
    if (touch.dy < -10) gotoSection(currentIndex + 1, 1);
  };

  useEffect(() => {
    document.addEventListener('keyup', handleArrowKey)
    return () => {
      document.removeEventListener('keyup', handleArrowKey);
    }
  }, [])


  const handleArrowKey = (event) => {
    console.log("logged")
    if (event.key === 'ArrowUp') {
      // Handle the up arrow key press
      if (animating) return;

      gotoSection(currentIndex - 1, -1);
      console.log('Up arrow key pressed');
      // Add your logic here
    } else if (event.key === 'ArrowDown') {
      gotoSection(currentIndex + 1, 1)
      // Handle the down arrow key press
      console.log('Down arrow key pressed');
      // Add your logic here
    }
  };

  useEffect(() => {
    sectionContainers = document.querySelectorAll(".section-container");
    componentContainers = document.querySelectorAll(".component-container");
    outerWrappers = gsap.utils.toArray(".outer-container");
    innerWrappers = gsap.utils.toArray(".inner-container");
    clamp = gsap.utils.clamp(0, sectionContainers.length - 1);

    document.addEventListener("wheel", handleWheel);
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);

    gsap.set(outerWrappers, { yPercent: 100 });
    gsap.set(innerWrappers, { yPercent: -100 });
console.log("logged in")
    gotoSection(0, 1);

    return () => {
      document.removeEventListener("wheel", handleWheel);
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);
};

const planAnimation = (index) => {
  switch (index) {
    case 0:
      mainAnimationTrigger();
      break;
    case 1:
      break;
    case 2:
      break;
    case 3:
      cssAnimationTrigger();
      break;
    case 4:
      svgAnimationTrigger();
      break;
    case 5:
      scheduleAnimationTrigger();
      break;
    case 6:
      gpsAnimationTrigger();
      break;
    case 7:
      break;
    default:
      break;
  }
};

const mainAnimationTrigger = () => {
  const mainsvganimation = gsap.timeline();
  mainsvganimation
    .set("#main_container_svg_image", { opacity: 1 })
    .from(
      ".groundround > path",
      {
        duration: 0.3,
        scale: 0,
        stagger: 0.05,
        transformOrigin: "center",
        ease: "circ.out",
      },
      0
    )
    .from(
      ".groundround_2 > path",
      {
        duration: 0.3,
        scale: 0,
        stagger: 0.05,
        transformOrigin: "center",
        ease: "circ.out",
      },
      0
    )
    .from(".bottompanel", {
      duration: 0.2,
      scale: 0,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .from(".mouse", {
      duration: 0.2,
      scale: 0,
      transformOrigin: "left",
      ease: "circ.out",
    })
    .from(
      "#keys > g",
      {
        duration: 0.2,
        scale: 0,
        stagger: { grid: [7, 15], each: 0.05, from: "0", ease: "bounce.Out" },
        opacity: 0,
        y: "-200",
        transformOrigin: "left",
        ease: "bounce.out",
      },
      1.5
    )
    .from(
      "#keys_2 > g",
      {
        duration: 0.2,
        scale: 0,
        stagger: { grid: [7, 15], each: 0.05, from: "10", ease: "bounce.In" },
        opacity: 0,
        y: "-200",
        transformOrigin: "left",
        ease: "bounce.out",
      },
      1.5
    )
    .from(".latopscreenback", {
      duration: 0.2,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from(".laptopscreenfront", {
      duration: 0.2,
      scale: 0,
      transformOrigin: "top left",
      ease: "circ.out",
    })
    .from(".website", {
      duration: 0.3,
      scale: 0,
      transformOrigin: "center",
      ease: "circ.out",
    })
    .from("#mainanimcloud", {
      duration: 0.3,
      scale: 0,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .set(
      "#uploadline",
      {
        strokeDasharray: 1263.6807861328125,
        strokeDashoffset: 1263.6807861328125,
      },
      0
    )
    .set(
      "#downloadline",
      { strokeDasharray: 1265.0625, strokeDashoffset: 1265.0625 },
      0
    )
    .to("#uploadline", {
      duration: 0.3,
      strokeDashoffset: 0,
      ease: "circ.out",
    })
    .from("#uploadlight > * ", {
      duration: 0.3,
      opacity: 0,
      stagger: { from: "end", each: 0.01 },
      ease: "circ.out",
    })
    .to("#downloadline", {
      duration: 0.3,
      strokeDashoffset: 2530.125,
      ease: "Power0.easeNone",
    })
    .from("#downloadlight > * ", {
      duration: 0.1,
      opacity: 0,
      stagger: 0.01,
      repeat: -1,
      ease: "circ.out",
    })
    .set("#barsanim > *", { scaleY: 0 })
    .from(
      "#barsanim > *",
      {
        duration: 0.5,
        transformOrigin: "bottom",
        scaleY: 0,
        stagger: { from: "random", each: 0.1 },
        ease: "circ.out",
        yoyoEase: true,
        repeat: -1,
      },
      5
    )
    .to(
      "#laptopscreenfront_4",
      2,
      {
        css: { filter: "drop-shadow(blue 0px 0px 30px)" },
        yoyo: true,
        repeat: -1,
      },
      5
    );
};

const cssAnimationTrigger = () => {
  const csseditoranim = gsap.timeline();
  csseditoranim
    .from("#css-background", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "circ.out",
    })
    .from("#css-ground", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from(
      "#css-ground_2",
      {
        duration: 0.25,
        scaleY: 0,
        transformOrigin: "bottom",
        ease: "bounce.out",
      },
      0.25
    )
    .from("#left-hand-table", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from(
      "#right-hand-table",
      {
        duration: 0.25,
        scaleY: 0,
        transformOrigin: "bottom",
        ease: "bounce.out",
      },
      0.5
    )
    .from("#table-head", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from("#css-left-drawer > path", {
      duration: 0.25,
      scaleX: 0,
      stgger: 0.5,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from(
      "#css-right-drawer > path",
      {
        duration: 0.25,
        scaleX: 0,
        stgger: 0.5,
        transformOrigin: "bottom",
        ease: "bounce.out",
      },
      1
    )
    .from("#keyboard", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from("#mouse", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .from("#tv-background", {
      duration: 0.25,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from("#tv-screen", {
      duration: 0.25,
      scaleX: 0,
      transformOrigin: "top left",
      ease: "bounce.out",
    })
    .from("#tv-image", {
      duration: 0.25,
      scaleX: 0,
      transformOrigin: "top left",
      ease: "bounce.out",
    })
    .from(
      "#tv-gear,#tv-gear_2",
      {
        duration: 0.25,
        scale: 0,
        transformOrigin: "center",
        ease: "bounce.out",
        rotate: "360",
      },
      2.25
    )
    .from(
      "#code-circle, #html-circle, #css-circle",
      {
        duration: 0.25,
        scale: 0,
        transformOrigin: "center",
        ease: "bounce.out",
        rotate: "360",
      },
      2.5
    )
    .from(
      "#html > g, #css > g, #code > g",
      {
        duration: 0.25,
        scaleY: 0,
        transformOrigin: "center",
        ease: "bounce.out",
        stgger: 0.25,
      },
      2.75
    )
    .from(
      "#gift, #blue-baloon, #red-baloon",
      {
        duration: 0.25,
        opacity: 0,
        y: 10,
        transformOrigin: "center",
        ease: "bounce.out",
      },
      3
    )
    .from(
      "#women, #men",
      {
        duration: 0.25,
        opacity: 0,
        y: 10,
        transformOrigin: "center",
        ease: "bounce.out",
      },
      3.5
    )
    .from(
      "#css-cloud-1,#css-cloud-2",
      {
        duration: 0.25,
        opacity: 0,
        scale: 0,
        transformOrigin: "center",
        ease: "bounce.out",
      },
      3.5
    )
    .from(
      "#css-small-gear,#css-big-gear",
      {
        duration: 0.25,
        opacity: 0,
        scale: 0,
        rotate: 360,
        transformOrigin: "center",
        ease: "bounce.out",
      },
      3.5
    )
    .from(
      "#css-bulp",
      {
        duration: 7,
        opacity: 0,
        scale: 0,
        transformOrigin: "center",
        ease: "circ.out",
        y: 200,
        repeat: -1,
        yoyo: true,
      },
      3.5
    );

  const cssRepeatAnim = gsap.timeline();
  cssRepeatAnim
    .to(
      "#css-small-gear",
      {
        duration: 10,
        rotate: 460,
        repeat: -1,
        transformOrigin: "center",
        ease: "none",
      },
      8
    )
    .to(
      "#css-big-gear",
      {
        duration: 10,
        rotate: 460,
        repeat: -1,
        transformOrigin: "center",
        ease: "none",
      },
      8
    )
    .to(
      "#css-cloud-1",
      {
        duration: 10,
        x: 200,
        ease: "none",
        repeat: -1,
        yoyo: true,
      },
      8
    )
    .to(
      "#css-cloud-2",
      {
        duration: 10,
        x: -200,
        repeat: -1,
        ease: "none",
        yoyo: true,
      },
      8
    );
};

const svgAnimationTrigger = () => {
  const svgeditoranim = gsap.timeline();
  svgeditoranim
    .from("#bottomstand", {
      duration: 0.2,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "power2.out",
    })
    .from("#topstand", {
      duration: 0.2,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from("#bottompanel", {
      duration: 0.3,
      scaleX: 0,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .from("#animatorscreen", {
      duration: 0.3,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "circ.out",
    })
    .from(
      "#lefttop",
      {
        duration: 0.3,
        scale: 0,
        transformOrigin: "top left",
        ease: "circ.out",
      },
      1.5
    )
    .from(
      "#righttop",
      {
        duration: 0.3,
        scale: 0,
        transformOrigin: "top right",
        ease: "circ.out",
      },
      1.5
    )
    .from(
      "#leftbottom",
      {
        duration: 0.3,
        scale: 0,
        transformOrigin: "bottom left",
        ease: "circ.out",
      },
      1.5
    )
    .from(
      "#rightbottom",
      {
        duration: 0.3,
        scaleX: 0,
        transformOrigin: "right",
        ease: "circ.out",
      },
      1.5
    )
    .from(
      "#svgimage",
      {
        duration: 0.3,
        scaleY: 0,
        transformOrigin: "top",
        ease: "circ.out",
      },
      1.5
    )
    .from(
      "#lefttopline",
      {
        duration: 0.7,
        scaleX: 0,
        transformOrigin: "left",
        ease: "circ.out",
      },
      1.7
    )
    .from(
      "#lefttopdot",
      {
        duration: 0.7,
        scaleX: 0,
        transformOrigin: "left",
        ease: "circ.out",
      },
      1.7
    )
    .from(
      "#leftbottomline",
      {
        duration: 0.7,
        scaleX: 0,
        transformOrigin: "left",
        ease: "circ.out",
      },
      1.7
    )
    .from(
      "#leftbottomdot",
      {
        duration: 0.7,
        scaleX: 0,
        transformOrigin: "left",
        ease: "circ.out",
      },
      1.7
    )
    .from(
      "#toprightline",
      {
        duration: 0.7,
        scaleX: 0,
        transformOrigin: "right",
        ease: "circ.out",
      },
      1.7
    )
    .from(
      "#rightbottomline > g",
      {
        duration: 1.5,
        scaleX: 0,
        stagger: 0.1,
        transformOrigin: "right",
        ease: "circ.out",
      },
      1.7
    )
    .from(
      "#fronttire",
      {
        rotate: 560,
        duration: 5,
        transformOrigin: "center",
        ease: "none",
        repeat: -1,
        yoyo: true,
      },
      2.5
    )
    .from(
      "#backtire",
      {
        rotate: 560,
        duration: 5,
        transformOrigin: "center",
        ease: "none",
        repeat: -1,
        yoyo: true,
      },
      2.5
    )
    .to(
      "#cyclesvg",
      {
        x: "-350",
        duration: 5,
        transformOrigin: "center",
        ease: "none",
        repeat: -1,
        yoyo: true,
      },
      2.5
    );
};

const scheduleAnimationTrigger = () => {
  const scheduleanimation = gsap.timeline();
  scheduleanimation
    .from("#header", {
      duration: 0.2,
      scaleX: 0,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .from("#schedulebody", {
      duration: 0.2,
      scale: 0,
      transformOrigin: "top left",
      ease: "circ.out",
    })
    .from("#boxes > path", {
      duration: 0.2,
      scale: 0,
      y: 40,
      ease: "power1.inOut",
      stagger: {
        grid: [7, 15],
        from: "random",
        ease: "power3.inOut",
        each: 0.05,
      },
    })
    .from("#ladder > #red, #ladder > #orange", {
      duration: 0.2,
      scaleY: 0,
      transformOrigin: "top",
      stagger: 0.1,
      ease: "bounce.out",
    })
    .from("#mansc", {
      duration: 0.2,
      scale: 0,
      transformOrigin: "top left",
      ease: "circ.out",
    })
    .from("#pencil", {
      duration: 0.2,
      scaleX: 0,
      transformOrigin: "right",
      ease: "circ.out",
    })
    .from("#orangecircle", { opacity: 0, duration: 0.2 })
    .from("#cloud", {
      duration: 0.2,
      opacity: 0,
      scale: 0,
      transformOrigin: "center",
      ease: "power2.out",
    });
};

const gpsAnimationTrigger = () => {
  const gpsanimation = gsap.timeline();
  gpsanimation
    .from("#geobackground", {
      duration: 0.3,
      scale: 0.5,
      opacity: 0,
      ease: "circ.out",
      transformOrigin: "center",
    })
    .from("#panelsub1", {
      duration: 0.3,
      scale: 0,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .from("#panelsub2, #panelsub3, #panelsub4", {
      duration: 0.3,
      scale: 0,
      stagger: 0.3,
      transformOrigin: "center",
      ease: "circ.out",
    })
    .from("#mainpointer", {
      duration: 0.3,
      scaleY: 0,
      transformOrigin: "bottom",
      ease: "bounce.out",
    })
    .from("#line", { duration: 0.3, opacity: 0, ease: "circ.out" })
    .from("#minpointer1,#minpointer2", {
      duration: 0.3,
      scale: 0,
      stagger: 0.5,
      ease: "bounce.out",
    })
    .from("#globe1,#globe2", {
      duration: 0.3,
      rotate: 360,
      scale: 0,
      stagger: 0.5,
      transformOrigin: "center",
      ease: "bounce.out",
    })
    .set("#mainpointer", { rotate: 0, transformOrigin: "bottom" }, 1.5)
    .to(
      "#mainpointer",
      { duration: 3, rotate: "-30", transformOrigin: "bottom", ease: "none" },
      1.5
    )
    .to(
      "#mainpointer",
      {
        duration: 3,
        rotate: "+30",
        transformOrigin: "bottom",
        ease: "none",
        yoyoEase: true,
        repeat: -1,
      },
      4.5
    )
    .set("#globe1", { rotate: 0, transformOrigin: "center" }, 5.2)
    .set("#globe2", { rotate: 0, transformOrigin: "center" }, 5.2)
    .from(
      "#globe1",
      {
        duration: 1,
        rotate: 0,
        transformOrigin: "center",
        ease: "none",
        repeat: -1,
        yoyo: true,
      },
      5.2
    )
    .from(
      "#globe2",
      {
        duration: 1,
        rotate: 0,
        transformOrigin: "center",
        ease: "none",
        repeat: -1,
        yoyo: true,
      },
      5.2
    );
};

export default useScroll;
